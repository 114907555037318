import React from "react"
import Layout from "../components/layout"
import Card from "../components/Card"
import twifThumb from "../images/work/twif/twif-thumb-1400.jpg"
import cfProductThumb2 from "../images/work/cf-product/cf-product-thumb-2-1400.jpg"
import cfMarketingThumb from "../images/work/cf-marketing/cf-marketing-thumb-1400.jpg"
import waveThumb from "../images/work/wave/wave-thumb-1400.jpg"
import olderWorkThumb from "../images/work/older-work-thumb-1400.jpg"
import Helmet from "react-helmet";
import { graphql } from "gatsby"


export default function Work({ data }) {
  return (
    <>
      <Helmet>
        <title>Work | {data.site.siteMetadata.title}</title>
        <meta name="description" content="Work from a variety of professional projects." />
      </Helmet>
      <Layout>
        <section className="content-body content-body--general pt-6 pb-5 animated fadeIn home-bg">
          <article className="intro row-g animated fadeIn">
            <section className="container-fluid">
              <div className="row py-0">
                <div className="col-md-12 col-lg-10 col-xl-8 px-0">
                  <h1 className="pb-3 homepage-title text-white">Geoff Agnew<br></br><span className="text-white-50">Product Design &amp; Software Development</span></h1>
                </div>
              </div>

              <div className="row my-5">
                <div className="col-lg-12 px-0">
                  <div className="cards-wrapper">
                    <Card 
                      thumbnail={cfProductThumb2} 
                      projectName="iManage" 
                      projectType="Product Design"
                      linkExternal="False"
                      projectLink="closing-folders"
                    />
                    <Card 
                      thumbnail={twifThumb} 
                      projectName="This Week in Food" 
                      projectType="Product Design & App Development"
                      linkExternal="False"
                      projectLink="this-week-in-food" 
                    />
                    <Card 
                      thumbnail={cfMarketingThumb} 
                      projectName="Closing Folders" 
                      projectType="Branding, Marketing, & Web Development"
                      linkExternal="False"
                      projectLink="closing-folders-marketing" 
                    />
                    <Card 
                      thumbnail={waveThumb} 
                      projectName="Wave" 
                      projectType="Growth"
                      linkExternal="False"
                      projectLink="wave"
                    />
                    <Card 
                      thumbnail={olderWorkThumb} 
                      projectName="Older Work" 
                      projectType="Product, Marketing, and Growth"
                      linkExternal="True"
                      projectLink="https://archive.geoffagnew.com/projects"
                    />
                  </div>
                </div>
              </div>

            </section>
          </article>
        </section>

      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`