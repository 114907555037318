import React from "react"
import { Link } from "gatsby"

export default function Card(props) {
  if(props.linkExternal === 'True') {
    return (
      <a className="custom-card" href={props.projectLink} target="_blank" rel="noreferrer">
        <div className="card-text-wrap">
          <h3 className="mb-1">{props.projectName}</h3>
          <p className="mb-0 text-white-50">{props.projectType}</p>
        </div>
        <div className="card-thumb-wrap">
          <img src={props.thumbnail} alt={props.projectName} />
        </div>
      </a>
    )
  } else {
    return (
      <Link className="custom-card" to={`/work/${props.projectLink}`}>
        <div className="card-text-wrap">
          <h3 className="mb-1">{props.projectName}</h3>
          <p className="mb-0 text-white-50">{props.projectType}</p>
        </div>
        <div className="card-thumb-wrap">
          <img src={props.thumbnail} alt={props.projectName} />
        </div>
      </Link>
    )
  }
}